import { BaseConfig } from '@danone-global/internal/react/core'
import { messages } from '@danone-global/internal/react/modules/checkout'
import React from 'react'

export const baseEnvironment: BaseConfig = {
  storeName: 'Aptamil',

  siteName: 'dach-am',

  siteSlug: 'dach-am',

  defaultLocale: 'de-DE',

  providerId: 'danis_eu',

  ctHost: 'https://api.europe-west1.gcp.commercetools.com',

  ctAuthHost: 'https://auth.europe-west1.gcp.commercetools.com',

  ctStoreKey: 'APTA',

  deliveryCategory: 'loprofin-bread',
  localeConfigs: [
    {
      locale: 'de-DE',
      country: 'DE',
      currency: 'EUR',
      initMessages: () =>
        import('../translations/de_DE.json').then((r) => r.default),
      urls: {
        faq: 'https://www.aptaclub.de/aptamil-produkte/faq.html',
        privacy: 'https://www.aptaclub.de/datenschutzerklaerung.html',
        dataCollection: 'https://www.aptaclub.de/datenschutzerklaerung.html',
        termsAndConditions: 'https://www.aptaclub.de/nutzungsbedingungen.html',
        contact: 'https://www.aptaclub.de/mein-aptaclub/kontakt.html',
      },
    },
  ],

  analytics: {
    tracker: 'aem',
    language: 'de-DE',
    defaultCategory: 'Baby food',
  },

  overrides: {
    'shopping-cart-v2': {
      variant: 'badge',
    },
  },

  passwordValidation: {
    mustHaveLowercaseAndUppercase: true,
    specialCharacters: 'DACH',
  },

  checkout: {
    stepAddress: React.lazy(
      () =>
        // imports should be on one line, in order to trigger transifex extract actions!
        // prettier-ignore
        import('@danone-global/internal/react/components/checkout/de-step-address'),
    ),
    payment: React.lazy(
      () =>
        // prettier-ignore
        import('@danone-global/internal/react/components/payment-providers/adyen-drop-in'),
    ),

    checkboxes: [
      {
        name: 'isMarketingOptedIn',
        required: false,
        store: true,
        label: messages.isMarketingOptedIn,
      },
      {
        name: 'agreeTerms',
        required: true,
        store: false,
        label: messages.agreeTerms,
      },
      {
        name: 'agreeDataSecurity',
        required: true,
        store: false,
        label: messages.agreeDataSecurity,
      },
    ],
  },
  signupStaticFields: {
    clubId: 'DE APTA',
    brand: 'Aptamil',
    division: 'SN',
    region: 'EMEA',
  },
}
