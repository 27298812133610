import { Config } from '@danone-global/internal/react/core'

import { baseEnvironment } from './environment.base'

export const environment: Config = {
  ...baseEnvironment,

  isProduction: true,

  isDevelopment: false,

  ctProjectKey: 'dach-prd-danone',

  ctClientId: 'vqhPCuOi9jCpZ4EW5RWdYh3h',

  ctClientSecret: 'ZPg5IcrghbNVEB7owQ0B6_98R5spqIQa',

  defaultChannelId: '503a13c6-cfee-4b36-98a4-9b13aaa015d1',

  sentryDSN:
    'https://26e95ebef18de217c29264c5f256280b@o4506427613577216.ingest.sentry.io/4506433323008000',

  // chargebee: {
  //   site: 'danone-dach-aptaclub',
  //   infoLink: 'https://www.aptaclub.de/aptamil-produkte/abo.html',
  // },

  loqate: {
    apiKey: 'NH44-PZ36-ER76-NP17',
    countries: ['DE'],
  },

  adyen: {
    clientKey: 'live_Q5CZHN2YWRFEZGJ357ETD6CRQYTS7W4Q',
    environment: 'live',

    googlePay: {
      environment: 'PRODUCTION',
      gatewayMerchantId: 'DanoneNutricia',
      merchantName: 'Nutricia Milupa GmbH',
      merchantId: 'BCR2DN6TT6SZHQQD',
    },
  },
}
